<template>
    <div>
        <a-table
            v-if="logs.data.length > 0"
            class="travel-table last-itens documents"
            :pagination="false"
            :columns="[
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    scopedSlots: {
                        customRender: 'id',
                    },
                },
                {
                    title: 'Descrição',
                    dataIndex: 'description',
                    key: 'description',
                    scopedSlots: {
                        customRender: 'description',
                    },
                },
                {
                    title: 'Usuário',
                    dataIndex: 'user',
                    key: 'user',
                    scopedSlots: {
                        customRender: 'user',
                    },
                },
                {
                    title: 'Data/Hora',
                    dataIndex: 'created',
                    key: 'created',
                    scopedSlots: {
                        customRender: 'created',
                    },
                },
            ]"
            :data-source="logs.data"
            :loading="milesCardsLoading"
        >
            <div slot="description" slot-scope="record" v-html="record"></div>

            <div slot="user" slot-scope="record">
                {{ record.first_name }}
                {{ record.last_name }}
            </div>

            <div slot="created" slot-scope="record">
                {{ formatDate(record) }}
            </div>
        </a-table>
        <div v-else class="" :class="empty ? 'no-results mt-60' : ''">
            <a-empty
                v-if="empty"
                description="Nenhuma alteração realizada até o momento."
            />
            <div class="a-center">
                {{ noResultsText }}
            </div>
        </div>
        <!-- {{ logs.data }} -->
    </div>
</template>

<script>
import { format, parse } from "date-fns";
export default {
    props: {
        logs: Array,
        showAction: Boolean,
        empty: Boolean,
        color: String,
        noResultsText: String,
    },
    data() {
        return {};
    },
    methods: {
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
    },
};
</script>
