<template>
  <div class="view contract view">
    <a-row>
      <a-col id="document-viewer" class="document-viewer" :span="24">
        <div id="scroll-top"></div>
        <div id="actions">
          <a-button
            size="default"
            type="primary"
            ghost
            @click="$router.push(`/miles-cards/list`)"
          >
            <a-icon type="arrow-left" />
          </a-button>

          <a-button
            size="default"
            type="primary"
            ghost
            class="ml-10"
            @click="printDocs(milesCard)"
          >
            <a-icon type="printer" />
          </a-button>

          <a-button
            class="ml-10"
            @click="generatePDF(milesCard)"
            size="default"
            type="danger"
            :loading="generatePDFLoading"
            ghost
          >
            <a-icon type="file-pdf" /> PDF
          </a-button>
        </div>
        <div id="the-outer-document">
          <div class="contract-table">
            <table id="a4" width="100%" border="0" class="a4">
              <tbody>
                <tr>
                  <td class="td">
                    <table
                      width="100%"
                      border="0"
                      style="border-bottom: 1px solid #ddd"
                    >
                      <tbody>
                        <tr>
                          <td width="40%">
                            <img src="../../assets/images/credimilhas.png" />
                          </td>
                          <td width="60%" style="text-align: right">
                            <div
                              style="
                                font-size: 16px;
                                color: #000;
                                font-weight: 600;
                                letter-spacing: -1px;
                                line-height: 1;
                              "
                            >
                              TRANSAÇÕES - CARTÃO DE MILHAS ID
                              {{ milesCard.id }}
                            </div>
                            <div>
                              {{ milesCard.miles_program }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="2"
                            width="100%"
                            style="height: 20px"
                          ></td>
                        </tr>
                      </tbody>
                    </table>

                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td
                            colspan="2"
                            width="100%"
                            style="height: 20px"
                          ></td>
                        </tr>
                        <tr>
                          <td width="40%">
                            <div
                              style="
                                font-size: 18px;
                                color: #000;
                                font-weight: 600;
                                letter-spacing: -1px;
                                line-height: 1;
                              "
                            >
                              ID
                              {{ milesCard.id }}
                            </div>
                            <div
                              style="
                                font-size: 14px;
                                color: #000;
                                font-weight: 600;
                                letter-spacing: -0.5px;
                                line-height: 1;
                                padding: 2px 0 5px 0;
                              "
                            >
                              {{ milesCard.supplier.first_name }}
                              {{ milesCard.supplier.last_name }}
                              -
                              {{ milesCard.supplier.cpf }}
                            </div>
                            <div
                              style="
                                font-size: 12px;
                                color: #777;
                                font-weight: 500;
                                letter-spacing: -0.5px;
                                line-height: 1;
                                padding: 2px 0 5px 0;
                              "
                            >
                              {{ milesCard.supplier.mobile_phone
                              }}{{
                                milesCard.supplier.phone
                                  ? " - " + milesCard.supplier.phone
                                  : ""
                              }}
                            </div>
                            <div
                              class="upper"
                              style="
                                font-size: 12px;
                                color: #777;
                                font-weight: 500;
                                letter-spacing: -0.5px;
                                line-height: 1;
                                padding: 2px 0 5px 0;
                              "
                            >
                              {{ milesCard.supplier.email }}
                            </div>
                            <div
                              style="
                                font-size: 12px;
                                color: #777;
                                font-weight: 500;
                                letter-spacing: -0.5px;
                                line-height: 1;
                                padding: 2px 0 5px 0;
                              "
                            >
                              <a-tag
                                v-if="milesCard.status == 'blocked'"
                                color="#f00"
                                style="
                                  font-size: 10px;
                                  line-height: 16px;
                                  border-radius: 3px;
                                "
                              >
                                BLOQUEADO
                              </a-tag>
                              <a-tag
                                v-if="milesCard.status == 'available'"
                                color="#00aa21"
                                style="
                                  font-size: 10px;
                                  line-height: 16px;
                                  border-radius: 3px;
                                "
                              >
                                DISPONÍVEL
                              </a-tag>
                            </div>
                          </td>
                          <td
                            width="60%"
                            style="text-align: right"
                            class="miles-card-counter"
                          >
                            <table style="width: 100%">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="box total">
                                      <div class="name">TOTAL</div>
                                      <div class="number">
                                        {{ milesCard.total_miles }}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="box available">
                                      <div class="name">DISPONÍVEL</div>
                                      <div class="number">
                                        {{ milesCard.available_miles }}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="box used">
                                      <div class="name">UTILIZADO</div>
                                      <div class="number">
                                        {{ milesCard.spent_miles }}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td
                            colspan="1"
                            width="100%"
                            style="height: 20px"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style="
                              font-size: 16px;
                              color: #000;
                              font-weight: 600;
                              letter-spacing: -0.5px;
                              line-height: 1;
                              padding: 2px 0 5px 0;
                            "
                          >
                            EXTRATO
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" width="100%" style="height: 5px"></td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="miles-card-modal">
                      <div class="miles-cards-tabs">
                        <a-table
                          class="travel-table last-itens documents"
                          :pagination="false"
                          :data-source="milesCard.transactions.data"
                          :loading="milesCardsLoading"
                          :columns="[
                            {
                              title: 'ID ',
                              dataIndex: 'milescard_transaction_id',
                              key: 'milescard_transaction_id',
                              class: 'milescard_transaction_id',
                            },
                            {
                              title: 'Milhas',
                              scopedSlots: {
                                customRender: 'total_miles',
                              },
                            },
                            {
                              title: 'Valor (R$)',
                              dataIndex: 'value',
                              key: 'value',
                            },
                            {
                              title: 'Valor/1000 milhas',
                              dataIndex: 'price_per_miles',
                              key: 'price_per_miles',
                            },
                            {
                              title: 'Preço Especial',
                              dataIndex: 'special_price',
                              key: 'special_price',
                            },
                            {
                              title: 'Operação',
                              scopedSlots: {
                                customRender: 'operation',
                              },
                            },
                            {
                              title: 'Observação',
                              scopedSlots: {
                                customRender: 'extra_info',
                              },
                              width: 100,
                            },
                            {
                              title: 'Usuário',
                              dataIndex: 'user',
                              key: 'user',
                              scopedSlots: {
                                customRender: 'user',
                              },
                            },
                            {
                              title: 'Data/Hora',
                              dataIndex: 'created',
                              key: 'created',
                              scopedSlots: {
                                customRender: 'created',
                              },
                            },
                          ]"
                        >
                          <div slot="total_miles" slot-scope="record">
                            <div
                              :class="
                                record.is_canceled == '1' ? 'line-through' : ''
                              "
                            >
                              {{ formatMilesOperation(record) }}
                            </div>
                          </div>
                          <a slot="quantity" slot-scope="record">
                            {{ record }}
                          </a>
                          <div slot="user" slot-scope="record">
                            {{ record.first_name }}
                            {{ record.last_name }}
                          </div>
                          <div slot="value" slot-scope="record">
                            {{ record }}
                          </div>

                          <div slot="operation" slot-scope="record">
                            <span v-html="formatOperation(record)" />
                          </div>

                          <div slot="extra_info" slot-scope="record">
                            <div
                              v-html="record.extra_info"
                              style="
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 10px;
                              "
                            />
                          </div>
                          <div slot="created" slot-scope="record">
                            {{ formatDateTime(record) }}
                          </div>
                        </a-table>
                      </div>
                    </div>

                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td
                            colspan="1"
                            width="100%"
                            style="height: 20px"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style="
                              font-size: 16px;
                              color: #000;
                              font-weight: 600;
                              letter-spacing: -0.5px;
                              line-height: 1;
                              padding: 2px 0 5px 0;
                            "
                          >
                            AUDITORIA
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" width="100%" style="height: 5px"></td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="miles-card-modal">
                      <div class="miles-cards-tabs">
                        <a-table
                          class="travel-table last-itens documents"
                          :pagination="false"
                          :data-source="milesCard.transactions.data"
                          :loading="milesCardsLoading"
                          :columns="[
                            {
                              title: 'ID ',
                              dataIndex: 'milescard_transaction_id',
                              key: 'milescard_transaction_id',
                              class: 'milescard_transaction_id',
                            },
                            {
                              title: 'Milhas',
                              scopedSlots: {
                                customRender: 'total_miles',
                              },
                            },

                            {
                              title: 'Operação',
                              scopedSlots: {
                                customRender: 'operation',
                              },
                            },

                            {
                              title: 'Voyage ID',
                              scopedSlots: {
                                customRender: 'voyage_id',
                              },
                            },
                            {
                              title: 'Data/Hora',
                              scopedSlots: {
                                customRender: 'created',
                              },
                            },
                            {
                              title: 'Info da auditoria',
                              scopedSlots: {
                                customRender: 'audit_comment',
                              },
                            },
                            {
                              title: 'Data Auditoria',
                              scopedSlots: {
                                customRender: 'audit_date',
                              },
                            },
                            {
                              title: 'USUARIO',
                              dataIndex: 'audit_user',
                              key: 'audit_user',
                              scopedSlots: {
                                customRender: 'audit_user',
                              },
                            },
                          ]"
                        >
                          <div slot="total_miles" slot-scope="record">
                            <div
                              :class="
                                record.is_canceled == '1' ? 'line-through' : ''
                              "
                            >
                              {{ formatMilesOperation(record) }}
                            </div>
                          </div>

                          <div slot="audit_user" slot-scope="record">
                            {{ record.first_name }}
                            {{ record.last_name }}
                          </div>

                          <div slot="voyage_id" slot-scope="record">
                            {{ record.voyage_id != 0 ? record.voyage_id : "" }}
                          </div>

                          <div slot="audit_date" slot-scope="record">
                            {{
                              record.audit_date != "30/11/-0001 00:00"
                                ? formatDateTime(record.audit_date)
                                : ""
                            }}
                          </div>

                          <div slot="audit_comment" slot-scope="record">
                            {{ record.audit_comment }}
                          </div>

                          <div slot="operation" slot-scope="record">
                            <span v-html="formatOperation(record)" />
                            <a-tooltip v-if="record.extra_info">
                              <template slot="title">
                                {{ record.extra_info }}
                              </template>
                              <a-icon
                                class="ml-5 relative c-pointer"
                                style="font-size: 12px; color: #aaa; top: 2px"
                                type="info-circle"
                              />
                            </a-tooltip>
                          </div>

                          <template slot="created" slot-scope="record">
                            {{ formatDateTime(record.created) }}
                          </template>
                        </a-table>
                      </div>
                    </div>

                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td
                            colspan="1"
                            width="100%"
                            style="height: 20px"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style="
                              font-size: 16px;
                              color: #000;
                              font-weight: 600;
                              letter-spacing: -0.5px;
                              line-height: 1;
                              padding: 2px 0 5px 0;
                            "
                          >
                            BLOQUEIOS
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <logs-table
                      :logs="milesCard.logs.blocks"
                      :empty="false"
                      noResultsText="Nenhum bloqueio até o momento."
                    />

                    <table width="100%" border="0">
                      <tbody>
                        <tr>
                          <td
                            colspan="1"
                            width="100%"
                            style="height: 20px"
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style="
                              font-size: 16px;
                              color: #000;
                              font-weight: 600;
                              letter-spacing: -0.5px;
                              line-height: 1;
                              padding: 2px 0 5px 0;
                            "
                          >
                            PAGAMENTOS
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <a-table
                      class="travel-table last-itens documents"
                      :pagination="false"
                      :columns="[
                        {
                          title: 'Lote',
                          key: 'batch',
                          scopedSlots: {
                            customRender: 'batch',
                          },
                        },

                        {
                          title: 'Nº Parcela',
                          dataIndex: 'installment_number',
                          key: 'installment_number',
                        },
                        {
                          title: 'Valor Fatura',
                          dataIndex: 'value',
                          key: 'value',
                          scopedSlots: {
                            customRender: 'value',
                          },
                        },
                        {
                          title: 'Qtd. Milhas',
                          dataIndex: 'miles_quantity',
                          key: 'miles_quantity',
                          scopedSlots: {
                            customRender: 'miles_quantity',
                          },
                        },
                        {
                          title: 'Status',
                          dataIndex: 'status',
                          key: 'status',
                          scopedSlots: {
                            customRender: 'status',
                          },
                        },
                        {
                          title: 'Vencimento',
                          dataIndex: 'expires_at',
                          key: 'expires_at',
                          scopedSlots: {
                            customRender: 'expires_at',
                          },
                        },
                        {
                          title: 'Data do pagamento',
                          dataIndex: 'payment_date',
                          key: 'payment_date',
                          scopedSlots: {
                            customRender: 'payment_date',
                          },
                        },
                        {
                          title: 'pago por:',
                          scopedSlots: {
                            customRender: 'payment_user',
                          },
                        },
                      ]"
                      :data-source="milesCard.payments.data"
                    >
                      <template slot="batch" slot-scope="record">
                        {{ record.transaction_id }}
                      </template>

                      <template slot="miles_card" slot-scope="record">
                        {{ record.id }}
                      </template>

                      <template slot="supplier" slot-scope="record">
                        {{ record.miles_card.supplier.first_name }}
                        {{ record.miles_card.supplier.last_name }}
                      </template>

                      <template slot="value" slot-scope="record">
                        R$ {{ record }}
                      </template>

                      <template slot="status" slot-scope="record">
                        <div v-html="formatPaymentStatus(record)"></div>
                      </template>

                      <template slot="expires_at" slot-scope="record">
                        {{ formatDate(record) }}
                      </template>

                      <template slot="payment_date" slot-scope="record">
                        {{ formatPaymentDate(record) }}
                      </template>

                      <template slot="payment_user" slot-scope="record">
                        {{ record.payment_user.first_name }}
                        {{ record.payment_user.last_name }}
                      </template>
                    </a-table>

                    <div v-if="bankRows.length > 0">
                      <table width="100%" border="0">
                        <tbody>
                          <tr>
                            <td
                              colspan="1"
                              width="100%"
                              style="height: 20px"
                            ></td>
                          </tr>
                          <tr>
                            <td
                              style="
                                font-size: 16px;
                                color: #000;
                                font-weight: 600;
                                letter-spacing: -0.5px;
                                line-height: 1;
                                padding: 2px 0 5px 0;
                              "
                            >
                              DADOS BANCÁRIOS
                            </td>
                          </tr>
                          <tr>
                            <td colspan="1" width="100%" style="height: 0"></td>
                          </tr>
                        </tbody>
                      </table>
                      <a-row
                        v-for="bank in bankRows"
                        :key="bank.id"
                        class="view-data"
                        style="padding: 15px 5px"
                        :gutter="15"
                      >
                        <a-col :span="10">
                          <div class="label">Banco</div>
                          <div class="value">
                            {{ bankNumber(supplier.meta[`bank_${bank.id}`]) }}
                            -
                            {{ supplier.meta[`bank_${bank.id}`] }}
                          </div>
                        </a-col>
                        <a-col :span="4">
                          <div class="label">Agência</div>
                          <div class="value">
                            {{ supplier.meta[`bank_${bank.id}_agency`] }}
                          </div>
                        </a-col>

                        <a-col :span="6">
                          <div class="label">Conta</div>
                          <div class="value">
                            {{ supplier.meta[`bank_${bank.id}_account`] }}
                          </div>
                        </a-col>

                        <a-col :span="4">
                          <div class="label">Dígito</div>
                          <div class="value">
                            {{ supplier.meta[`bank_${bank.id}_account_digit`] }}
                          </div>
                        </a-col>
                      </a-row>
                    </div>

                    <div
                      class="mt-30 a-right"
                      style="font-size: 11px; color: #aaa"
                    >
                      Data impressão:
                      <b style="font-weight: 600">
                        {{ todayDate() }}
                      </b>
                      <br />
                      Usuário:
                      <b style="font-weight: 600">
                        {{ this.$store.state.userData.first_name }}
                        {{ this.$store.state.userData.last_name }}
                      </b>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-col>
      <a-col :span="24">
        <div id="totop">
          <a-button
            type="link"
            shape="circle"
            icon="up-circle"
            @click="toTop"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import jq from "jquery";
import html2pdf from "html2pdf.js";
import { format, parse } from "date-fns";
import LogsTable from "@/components/logs/LogsTable.vue";
import banks from "@/json/banks.json";

export default {
  name: "MilesCardsView",
  components: { LogsTable },
  data() {
    return {
      milesCard: {},
      generatePDFLoading: false,
      bankRows: [],
      supplier: {},
      banks,
    };
  },
  beforeMount() {
    this.getMilesCard();
  },
  methods: {
    theBank(id) {
      id;
      this.banks;
    },
    formatDateTime(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    formatPaymentStatus(status) {
      if (status == "paid") {
        status = `<font class="tag add">PAGO</font>`;
      }

      if (status == "refund") {
        status = `<font class="tag new">REEMBOLSADO</font>`;
      }

      if (status == "pending") {
        status = `<font class="tag subtract">PENDENTE</font>`;
      }

      return status;
    },
    formatPaymentDate(date) {
      if (date == "0000-00-00 00:00:00") {
        return "Não realizado";
      } else {
        let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
        return format(theDate, "dd/MM/yyyy HH:mm");
      }
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    todayDate() {
      return format(new Date(), "dd/MM/yyyy HH:mm");
    },
    bankNumber(name) {
      let theNumber = this.banks.filter((e) => {
        return e.nome == name;
      });

      return theNumber[0].numero;
    },
    formatOperation(transaction) {
      let operation = "";
      let vcm = "";

      if (transaction.operation == "new") {
        if (transaction.is_vcm == "1") {
          vcm = `<font class="corange" style="font-size: 24px; font-size: 16px;"> <a-tooltip> <template slot="title"> Milhas do VCM </template> • </a-tooltip> </font>`;
        }

        operation = `<font class="tag new">NOVO ${vcm}</font>`;
      }

      if (transaction.operation == "add") {
        operation = `<font class="tag add">ADIÇÃO</font>`;
      }

      if (transaction.operation == "subtract") {
        operation = `<font class="tag return">EMISSÃO</font>`;
      }

      if (transaction.operation == "transaction-canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      if (transaction.operation == "canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      if (transaction.operation == "refund") {
        operation = `<font class="tag cprimary">REEMBOLSO</font>`;
      }

      if (transaction.operation == "adjust") {
        operation = `<font class="tag adjust">AJUSTE</font>`;
      }

      if (transaction.operation == "purchase-canceled") {
        operation = `<font class="tag cancel-purchase">CANCELAMENTO DE COMPRA</font>`;
      }

      return operation;
    },
    formatMilesOperation(transaction) {
      let miles = "";
      if (transaction.operation == "new") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "add") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "refund") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "return-miles") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "transaction-canceled") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "canceled") {
        miles = `+${transaction.total_miles}`;
      }

      if (transaction.operation == "subtract") {
        miles = `-${transaction.total_miles}`;
      }
      return miles;
    },
    refundPayments(payments) {
      let refunds = [];
      payments.forEach((payment) => {
        if (payment.status == "refund") {
          refunds.push(payment);
        }
      });

      return refunds;
    },
    getMilesCard() {
      this.$http
        .post("/miles-card/details", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.documentFirstLoading = false;
          this.milesCard = data;

          document.title = `${data.id} ${data.miles_program} - ${data.supplier.first_name} ${data.supplier.last_name}`;
          this.bankRows = JSON.parse(data.supplier.meta.bank_rows);
          this.supplier = data.supplier;
        });
    },
    toTop() {
      jq(".document-viewer").animate(
        {
          scrollTop: document.getElementById("scroll-top").offsetTop - 60,
        },
        500
      );
    },
    printDocs(milesCard) {
      document.title = `${milesCard.id} ${milesCard.miles_program} - ${milesCard.supplier.first_name} ${milesCard.supplier.last_name}`;
      window.print();
    },
    generatePDF(milesCard) {
      this.generatePDFLoading = true;

      let element = document.getElementById("the-outer-document");
      let options = {
        filename: `${milesCard.id} ${milesCard.miles_program} - ${milesCard.supplier.first_name} ${milesCard.supplier.last_name}.pdf`,
        image: { type: "jpeg", quality: 1 },
        enableLinks: false,
        margin: [8, 8, 8, 8],
        html2canvas: { scale: 2 },
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
      };

      html2pdf().from(element).set(options).save();

      this.generatePDFLoading = false;
    },
  },
};
</script>

<style>
.document-viewer {
  padding: 50px 40px !important;
  background: #ccc;
  height: 84vh !important;
  overflow: auto;
  box-shadow: inset 0 0 100px #929292;
  border-radius: 3px;
  border: 1px solid #ddd;
}

#actions {
  position: fixed;
  margin-left: -20px;
  margin-top: -30px;
}

#actions button {
  background: #fff !important;
}

@media print {
  body * {
    visibility: hidden;
  }

  .router-tab__container {
    overflow-x: visible;
    overflow-y: visible;
  }
  @page {
    size: auto;
    margin: 20px 20px;
  }

  #embed-pdf {
    display: none;
  }

  #the-outer-document,
  #the-outer-document * {
    visibility: visible;
  }

  .sidebar.ant-col.ant-col-6 {
    position: absolute;
  }
  .contract.view {
    background: #fafafa;
    padding: 0;
    margin: 0;
  }

  .dashboard .ant-layout-content {
    margin: 0 !important;
    position: absolute;
    left: 0;
    padding: 0 !important;
  }

  .document-viewer {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .ant-row {
    position: absolute !important;
    left: 0 !important;
  }

  .ant-tabs {
    position: absolute;
    left: 0;
    top: 0;
  }

  #the-outer-document {
    position: absolute;
    left: 0;
    top: 0;
  }

  .ant-page-header-ghost {
    position: absolute;
  }

  .contract.view .contract-table {
    box-shadow: none;
  }

  #contrato {
    display: none;
  }

  .contract.view .contract-table .a4 {
    height: 1006px !important;
  }

  .contract.view .contract-table .a4 .td {
    height: 1016px !important;
  }

  .document-viewer {
    padding: 0px !important;
    background: transparent;
    height: 100%;
    overflow: initial;
    box-shadow: none;
    border-radius: 0;
    border: 0;
  }

  @media only screen and (min-device-width: 1601px) and (max-width: 3000px) {
    #pg-print {
      height: auto !important;
    }
  }
}
</style>
