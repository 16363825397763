var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.logs.data.length > 0)?_c('a-table',{staticClass:"travel-table last-itens documents",attrs:{"pagination":false,"columns":[
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                scopedSlots: {
                    customRender: 'id',
                },
            },
            {
                title: 'Descrição',
                dataIndex: 'description',
                key: 'description',
                scopedSlots: {
                    customRender: 'description',
                },
            },
            {
                title: 'Usuário',
                dataIndex: 'user',
                key: 'user',
                scopedSlots: {
                    customRender: 'user',
                },
            },
            {
                title: 'Data/Hora',
                dataIndex: 'created',
                key: 'created',
                scopedSlots: {
                    customRender: 'created',
                },
            },
        ],"data-source":_vm.logs.data,"loading":_vm.milesCardsLoading},scopedSlots:_vm._u([{key:"description",fn:function(record){return _c('div',{domProps:{"innerHTML":_vm._s(record)}})}},{key:"user",fn:function(record){return _c('div',{},[_vm._v("\n            "+_vm._s(record.first_name)+"\n            "+_vm._s(record.last_name)+"\n        ")])}},{key:"created",fn:function(record){return _c('div',{},[_vm._v("\n            "+_vm._s(_vm.formatDate(record))+"\n        ")])}}],null,false,3148034000)}):_c('div',{class:_vm.empty ? 'no-results mt-60' : ''},[(_vm.empty)?_c('a-empty',{attrs:{"description":"Nenhuma alteração realizada até o momento."}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"a-center"},[_vm._v("\n            "+_vm._s(_vm.noResultsText)+"\n        ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }